<template>
    <div class="mt-4">
        <v-data-table
            id="virtual-scroll-table"
            :headers="headers"
            :items="bom.items || []"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            multiple-expand
            :show-expand="bom.items != undefined && bom.status == 'approved'"
            :style="`height: ${itemsHeight}px`"
            :expanded.sync="expanded"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex">
                        <h2 class="mx-4 my-n3">ITEMS</h2>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            class="mr-3"
                            v-if="
                                BOMSelected.length > 0 &&
                                    validateDeliver &&
                                    deliveryBOMPermission &&
                                    enableOptions &&
                                    partialDelivery
                            "
                            @click="openAddNewDelivery"
                        >
                            DELIVER
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.index`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.code }}</p>
                </div>
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <div class="d-flex">
                    <p class="my-0">{{ item.description }}</p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.quantity }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.partial`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.requests }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.store`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.store }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.notes }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.deliver`]="{ item }">
                <div
                    class="mb-5"
                    v-if="enableOptions && item.requests > 0 && partialDelivery"
                >
                    <v-text-field
                        v-model="item.quantityToDeliver"
                        hide-details
                        type="number"
                        :rules="[rules.number, validateQuantity(item)]"
                    />
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div
                    class="d-flex justify-center"
                    v-if="
                        bom.status == 'inProgress' || bom.status == 'rejected'
                    "
                >
                    <v-btn
                        v-if="!readBOMOnly"
                        small
                        icon
                        @click="openBomItemForm(item)"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="!readBOMOnly"
                        small
                        icon
                        @click="openDeleteBomItem(item)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        v-if="item.deliveryRejectionLog"
                        small
                        icon
                        @click="openRejectedLog(item)"
                        color="red"
                        class="ml-2"
                    >
                        <v-icon>
                            mdi-book-cancel-outline
                        </v-icon>
                    </v-btn>
                </div>
            </template>
            <!--EXPAND TABLE-->
            <template
                v-slot:expanded-item="{ headers, item: item }"
                v-if="bom.status == 'approved' || bom.status == 'closed'"
            >
                <td
                    :colspan="headers.length"
                    class="pa-4 ma-0 outlined"
                    style="background: white"
                >
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-data-table
                                :headers="deliveryLogHeaders"
                                :items="item.deliveries"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                            >
                                <template
                                    v-slot:[`item.deliveredBy`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{ findUserName(item.deliveredBy) }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.deliveredTo`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{ item.operator.name }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.date`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{
                                            formatDate(
                                                item.date._seconds ||
                                                    item.date.seconds
                                            )
                                        }}
                                    </p>
                                </template>

                                <template
                                    v-slot:[`item.qty`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{ item.qty }}
                                    </p>
                                </template>

                                <template
                                    v-slot:[`item.sap`]="{
                                        item,
                                    }"
                                >
                                    <v-simple-checkbox
                                        v-if="item.id || bom.status == 'closed'"
                                        :disabled="
                                            !user.permissions.includes(
                                                'closePartial'
                                            ) || bom.status == 'closed'
                                        "
                                        color="success"
                                        v-model="item.sap"
                                        @click="checkSAP(item)"
                                    ></v-simple-checkbox>
                                </template>

                                <template
                                    v-slot:[`item.notes`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{ item.notes }}
                                    </p>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-dialog
            v-model="addNewDelivery"
            persistent
            :retain-focus="false"
            max-width="600"
        >
            <AddNewDelivery
                v-if="addNewDelivery"
                :bom="bom"
                :items="BOMSelected"
                :requestData="requestData"
                @close="closeAddNewDelivery"
            />
        </v-dialog>
        <!--BOM ITEM FORM-->
        <v-dialog v-model="bomItemForm" persistent max-width="800">
            <BOMItemForm
                v-if="bomItemForm"
                :bom="bom"
                :itemToFilter="selectedItem"
                :isDeleteOption="isDeleteOption"
                @close="closeBomItemForm"
            />
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="800px" v-model="rejectionTable">
            <RejectionStockItemsTable
                v-if="rejectionTable"
                :items="rejectionItems"
                :users="users"
                @closeDialog="closeRejectedLog"
            />
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'ApprovalBOMItems',
    props: {
        bom: {
            type: Object,
            default: () => {},
        },
        itemsHeight: {
            type: Number,
            default: () => 0,
        },
        readBOMOnly: {
            type: Boolean,
            default: () => false,
        },
        partialDelivery: {
            type: Boolean,
            required: true,
        },
        requestData: {
            type: Object,
            required: false,
        },
    },
    components: {
        AddNewDelivery: () =>
            import('@/components/WorkOrders/AddNewDelivery.vue'),
        BOMItemForm: () => import('@/components/WorkOrders/BOMItemForm.vue'),
        RejectionStockItemsTable: () =>
            import('@/components/WorkOrders/RejectionStockItemsTable.vue'),
    },
    data: () => ({
        enableOptions: true,
        addNewDelivery: false,
        height: 0,
        selectedBOM: undefined,
        processBOM: false,
        processName: 'Approve Bill Of Material',
        projectToFilter: undefined,
        loading: false,
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'total',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REMAINING',
                value: 'remaining',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STORE',
                value: 'store',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESTINATION',
                value: 'destination',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
        ],
        deliveryLogHeaders: [
            {
                text: 'DELIVERED BY',
                value: 'deliveredBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERED TO',
                value: 'deliveredTo',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },

            {
                text: 'DELIVERED',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SAP',
                value: 'sap',
                align: 'center',
                sortable: false,
            },
        ],
        expanded: [],
        company: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            production: '#FFC000',
            cancelled: 'red',
            closed: 'primary',
        },
        rules: {
            number: v => v > 0 || 'The value should be greater than zero',
        },
        deliveryBOMPermission: false,
        user: {},
        users: [],
        bomItemForm: false,
        selectedItem: undefined,
        isDeleteOption: false,
        routeValidation: false,
        rejectionTable: false,
        closeDialog: false,
        rejectionItems: [],
    }),
    computed: {
        BOMSelected() {
            return this.bom.items
                ? this.bom.items.filter(item => item.quantityToDeliver > 0)
                : this.items.filter(item => item.quantityToDeliver > 0)
        },
        validateDeliver() {
            for (const item of this.bom.items) {
                if (item.quantityToDeliver && !this.validateQuantity(item)) {
                    return false
                }
            }
            return true
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        'bom.items': function() {
            this.bom.items.forEach(item => {
                if (item.deliveries) {
                    item.deliveries.forEach(delivery => {
                        delivery.itemCode = item.code
                    })
                }
            })
            if (
                (this.bom.deliver && this.deliveryBOMPermission) ||
                this.bom.items.find(item => item.requests > 0)
            ) {
                this.headers = this.headers.filter(
                    header =>
                        header.value != 'partial' && header.value != 'deliver'
                )
                this.headers.splice(4, 0, {
                    text: 'PARTIAL',
                    value: 'partial',
                    align: 'center',
                    sortable: false,
                })
                this.headers.push({
                    text: 'Deliver',
                    value: 'deliver',
                    align: 'center',
                    sortable: false,
                    width: '150',
                })
            }
        },
    },
    async mounted() {
        try {
            this.routeValidation =
                this.$router.currentRoute.name == 'boms-delivered'
                    ? false
                    : true
            if (this.bom.status == 'approved') {
                this.readOnlyApproved = true
            }
            this.onResize()
            if (
                this.$router.currentRoute.name == 'project-bill-of-materials' ||
                this.$router.currentRoute.name ==
                    'archive-project-bill-of-materials'
            ) {
                this.enableOptions = false
            }
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            if (user.permissions.includes('deliveryBOM')) {
                this.deliveryBOMPermission = true
            }
            if (
                (this.bom.deliver && this.deliveryBOMPermission) ||
                this.bom.items.find(item => item.requests > 0)
            ) {
                this.headers.splice(4, 0, {
                    text: 'PARTIAL',
                    value: 'partial',
                    align: 'center',
                    sortable: false,
                })
                if (this.routeValidation) {
                    this.headers.push({
                        text: 'Deliver',
                        value: 'deliver',
                        align: 'center',
                        sortable: false,
                        width: '150',
                    })
                }
            }
            if (
                this.bom.status == 'inProgress' ||
                this.bom.status == 'rejected' ||
                this.bom.status == 'approved'
            ) {
                this.headers.push({
                    text: 'ACTIONS',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                })
            }
            this.expanded = this.bom.items

            this.bom.items.forEach(item => {
                if (item.deliveries) {
                    item.deliveries.forEach(delivery => {
                        delivery.itemCode = item.code
                    })
                }
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        validateQuantity(item) {
            return item.requests >= Number(item.quantityToDeliver)
        },
        closeAddNewDelivery() {
            this.addNewDelivery = false
            this.$emit('closeProcessBOM')
        },
        openAddNewDelivery() {
            this.addNewDelivery = true
        },
        onResize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer -
                300
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        findUserName(userId) {
            if (this.users && this.users.length > 0) {
                const user = this.users.find(user => user.id == userId)
                if (user) {
                    return user.name
                } else {
                    return undefined
                }
            } else {
                return undefined
            }
        },
        closeRejectedLog() {
            this.rejectionItems = null
            this.rejectionTable = false
        },
        openRejectedLog(item) {
            this.rejectionItems = item.deliveryRejectionLog
            this.rejectionTable = true
        },
        openBomItemForm(item) {
            this.selectedItem = item
            this.bomItemForm = true
            this.isDeleteOption = false
        },
        closeBomItemForm(itemsUpdated) {
            this.bom.items = itemsUpdated
            this.bom = _.cloneDeep(this.bom)
            this.$emit('updateBOM', this.bom)
            this.selectedItem = undefined
            this.bomItemForm = false
        },
        openDeleteBomItem(item) {
            this.selectedItem = item
            this.bomItemForm = true
            this.isDeleteOption = true
        },
        async checkSAP(item) {
            try {
                await API.updateBOMItem({
                    workOrderId: this.bom.workOrderId,
                    bomId: this.bom.id,
                    itemCode: item.itemCode,
                    deliveryId: item.id,
                    sap: item.sap,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
